var exports = {};
// Copyright 2014 Simon Lydell
// X11 (“MIT”) Licensed. (See LICENSE.)
void function (root, factory) {
  exports = factory();
}(exports, function () {
  var innerRegex = /[#@] sourceMappingURL=([^\s'"]*)/;
  var regex = RegExp("(?:" + "/\\*" + "(?:\\s*\r?\n(?://)?)?" + "(?:" + innerRegex.source + ")" + "\\s*" + "\\*/" + "|" + "//(?:" + innerRegex.source + ")" + ")" + "\\s*");
  return {
    regex: regex,
    _innerRegex: innerRegex,
    getFrom: function (code) {
      var match = code.match(regex);
      return match ? match[1] || match[2] || "" : null;
    },
    existsIn: function (code) {
      return regex.test(code);
    },
    removeFrom: function (code) {
      return code.replace(regex, "");
    },
    insertBefore: function (code, string) {
      var match = code.match(regex);

      if (match) {
        return code.slice(0, match.index) + string + code.slice(match.index);
      } else {
        return code + string;
      }
    }
  };
});
export default exports;